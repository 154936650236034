import { useNavigate } from "react-router-dom";
import { Image, LI, IconButtonSF } from "../../../../AbstractElements";
import { dynamicImage } from "../../../../Service";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { logout } from "../../../../ReduxToolkit/Reducers/UserSlice";
import AuthService from "../../../../Api/AuthService";

// import ProfileBox from "./ProfileBox";

const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userData);

  const handleLogout = async () => {
    console.log("Logout clicked");
    await AuthService.logout();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch(logout());
    navigate(`${process.env.REACT_APP_API_URL}/login`);
  };

  return (
    // <LI className="profile-nav onhover-dropdown p-0">
    <LI className="profile-nav p-0">
      <div className="d-flex profile-media align-items-center">
        {/* <Image
          className="b-r-25 img-40"
          src={dynamicImage("dashboard/profile.png")}
          alt="user"
        /> */}
        <div className="flex-grow-1">
          <span className="header-user-name">{user?.name}</span>
          {/* <P className="mb-0">{"UI Designer"}</P> */}
        </div>
        <IconButtonSF
          className="ms-4"
          onClick={handleLogout}
          color="white"
          iconName="arrow-exit-20-regular"
          iconSize="34"
        />
      </div>
      {/* <ProfileBox /> */}
    </LI>
  );
};

export default UserProfile;
