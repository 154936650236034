import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          General: "General",
          Dashboards: "Dashboards",
          Default: "Default",
          Project: "Project",
          Ecommerce: "Ecommerce",
          Education: "Education",
          Widgets: "Widgets",
          Chart: "Chart",
          Applications: "Applications",
          "Project List": "Project List",
          "Create New": "Create New",
          "File Manager": "File Manager",
          "Add Product": "Add Product",
          Product: "Product",
          "Product Page": "Product Page",
          "Product List": "Product List",
          "Payment Details": "Payment Details",
          "Order History": "Order History",
          Invoice: "Invoice",
          "Invoice-1": "Invoice-1",
          "Invoice-2": "Invoice-2",
          "Invoice-3": "Invoice-3",
          "Invoice-4": "Invoice-4",
          "Invoice-5": "Invoice-5",
          "Invoice-6": "Invoice-6",
          Cart: "Cart",
          Wishlist: "Wishlist",
          Checkout: "Checkout",
          Pricing: "Pricing",
          Chat: "Chat",
          Users: "Users",
          "Letter Box": "Letter Box",
          "Private Chat": "Private Chat",
          "Group Chat": "Group Chat",
          "User Profile": "User Profile",
          "User Edit": "User Edit",
          "User Cards": "User Cards",
          Bookmarks: "Bookmarks",
          Contacts: "Contacts",
          Task: "Task",
          Calendar: "Calendar",
          "Social App": "Social App",
          Todo: "Todo",
          "Search Result": "Search Result",
          "Form Validation": "Form Validation",
          "Base Inputs": "Base Inputs",
          "Checkbox & Radio": "Checkbox & Radio",
          "Input Groups": "Input Groups",
          "Input Mask": "Input Mask",
          "Mega Option": "Mega Option",
          Datepicker: "Datepicker",
          Touchspin: "Touchspin",
          Switch: "Switch",
          Forms: "Forms",
          "Form Controls": "Form Controls",
          "Forms & Table": "Forms & Table",
          Typeahead: "Typeahead",
          Clipboard: "Clipboard",
          "Form Widget": "Form Widget",
          "Form Wizard 1": "Form Wizard 1",
          "Form Wizard 2": "Form Wizard 2",
          "Two Factor": "Two Factor",
          "Form Layout": "Form Layout",
          Table: "Table",
          "Reactstrap Tables": "Reactstrap Tables",
          "Basic Tables": "Basic Tables",
          "Table Components": "Table Components",
          "Data Tables": "Data Tables",
          "Basic Init": "Basic Init",
          "Advance Init": "Advance Init",
          API: "API",
          "Data Source": "Data Source",
          Components: "Components",
          Typography: "Typography",
          Avatars: "Avatars",
          "Helper Classes": "Helper Classes",
          Grid: "Grid",
          "Tag & Pills": "Tag & Pills",
          Progress: "Progress",
          Modal: "Modal",
          Alert: "Alert",
          Popover: "Popover",
          Tooltip: "Tooltip",
          Dropdown: "Dropdown",
          Accordion: "Accordion",
          Tabs: "Tabs",
          Lists: "Lists",
          "Bonus-Ui": "Bonus-Ui",
          Scrollable: "Scrollable",
          "Tree View": "Tree View",
          Toasts: "Toasts",
          Rating: "Rating",
          Dropzone: "Dropzone",
          Tour: "Tour",
          SweetAlert2: "SweetAlert2",
          "Owl Carousel": "Owl Carousel",
          Ribbons: "Ribbons",
          Pagination: "Pagination",
          Breadcrumb: "Breadcrumb",
          "Range Slider": "Range Slider",
          "Image Cropper": "Image Cropper",
          "Basic Card": "Basic Card",
          "Creative Card": "Creative Card",
          Timeline: "Timeline",
          Icons: "Icons",
          "Flag Icon": "Flag Icon",
          "Fontawesome Icon": "Fontawesome Icon",
          "Ico Icon": "Ico Icon",
          "Themify Icon": "Themify Icon",
          "Feather Icon": "Feather Icon",
          "Weather Icon": "Weather Icon",
          Buttons: "Buttons",
          "Default Style": "Default Style",
          "Flat Style": "Flat Style",
          "Edge Style": "Edge Style",
          "Raised Style": "Raised Style",
          "Button Group": "Button Group",
          Charts: "Charts",
          "Apex Chart": "Apex Chart",
          "Google Chart": "Google Chart",
          "Chart JS Chart": "Chart JS Chart",
          Pages: "Pages",
          "Sample Page": "Sample Page",
          Others: "Others",
          "Error Pages": "Error Pages",
          "Error 400": "Error 400",
          "Error 401": "Error 401",
          "Error 403": "Error 403",
          "Error 404": "Error 404",
          "Error 500": "Error 500",
          "Error 503": "Error 503",
          "Login Simple": "Login Simple",
          "Login with Bg Image": "Login with bg Image",
          "Login With Image Two": "Login With Image Two",
          "Login With Validation": "Login With Validation",
          "Login With Tooltip": "Login With Tooltip",
          "Login With Sweetalert": "Login With Sweetalert",
          "Register Simple": "Register Simple",
          "Register With Bg Image": "Register With Bg Image",
          "Register With Bg Two": "Register With Bg Two",
          "Register Wizard": "Register Wizard",
          "Unlock User": "Unlock User",
          "Forget Password": "Forget Password",
          "Reset Password": "Reset Password",
          Maintenance: "Maintenance",
          "Coming Simple": "Coming Simple",
          "Coming With Bg Video": "Coming With Bg Video",
          "Coming With Bg Image": "Coming With Bg Image",
          Miscellaneous: "Miscellaneous",
          Gallery: "Gallery",
          "Gallery Grids": "Gallery Grids",
          "Gallery Grid Desc": "Gallery Grid Desc",
          "Masonry Gallery": "Masonry Gallery",
          "Masonry With Desc": "Masonry With Desc",
          "Hover Effect": "Hover Effect",
          Blog: "Blog",
          "Blog Details": "Blog Details",
          "Blog Single": "Blog Single",
          "Add Post": "Add Post",
          FAQ: "FAQ",
          "Job Search": "Job Search",
          "Cards View": "Cards View",
          "List View": "List View",
          "Job Detail": "Job Detail",
          Apply: "Apply",
          Learning: "Learning",
          "Learning List": "Learning List",
          "Detailed Course": "Detailed Course",
          Map: "Map",
          "Google Map": "Google Map",
          "Leaflet Map": "Leaflet Map",
          Editor: "Editor",
          "CK Editor": "CK Editor",
          "MDE Editor": "MDE Editor",
          "ACE Editor": "ACE Editor",
          Knowledgebase: "Knowledgebase",
          SupportTicket: "SupportTicket",
          Authentication: "Authentication",
          "Login With Bg Image": "Login With Bg Image",
          "Coming Soon": "Coming Soon",
          Pinned: "Pinned",
        },
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
