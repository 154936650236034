import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Collapse,
} from "reactstrap";
import { Btn, H4, P } from "../../AbstractElements";
import { useState } from "react";
import { toast } from "react-toastify";
import { newPasswordSchema } from "../../utils/validation/schemas";
import Config from "../../Config/config";
import AuthService from "../../Api/AuthService";
import { isSafari } from "../../utils/helper/helper";
import LogoImage from "../LogoImage/LogoImage";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const [show, setShow] = useState(false);
  const [password, setPassword] = useState(Config.DEFAULT_PASSWORD);
  const [repeatPassword, setRepeatPassword] = useState(Config.DEFAULT_PASSWORD);
  const [errors, setErrors] = useState<{
    password: string;
    repeatPassword: string;
    passwordNotMatch: string;
    noToken: string;
  }>({
    password: "",
    repeatPassword: "",
    passwordNotMatch: "",
    noToken: "",
  });

  const handleConfirmReset = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const newErrors = {
      password: "",
      repeatPassword: "",
      passwordNotMatch: "",
      noToken: "",
    };
    setErrors((_prevState) => newErrors);

    const validation = newPasswordSchema.safeParse({
      password,
      repeatPassword,
    });

    if (!validation.success) {
      validation.error.errors.forEach((error) => {
        const field = error.path[0] as keyof typeof newErrors;
        if (!newErrors[field]) {
          newErrors[field] = error.message;
        }
      });

      setErrors((_prevState) => newErrors);
      return;
    }

    if (password && repeatPassword && password !== repeatPassword) {
      setErrors((errors) => {
        return { ...errors, passwordNotMatch: "Passwords do not match" };
      });
      return;
    }

    if (!token) {
      setErrors((errors) => {
        return {
          ...errors,
          noToken: "Token is missing. Request new password again.",
        };
      });
      return;
    }

    const resetPasswordResult = await AuthService.passwordReset({
      password,
      password_confirmation: repeatPassword,
      token: token as string,
    });

    console.log("registerResult", resetPasswordResult);

    toast.success("Den nye adgangskode blev gemt.");
    setTimeout(() => {
      navigate(`${process.env.REACT_APP_API_URL}/login`);
    }, 2000);
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div className="login-main">
                <div className="d-flex justify-content-center image-with-border mb-3">
                  <LogoImage />
                </div>
                <Form
                  className="theme-form"
                  onSubmit={handleConfirmReset}
                  noValidate
                >
                  <H4>Indstil ny adgangskode</H4>
                  <P>Indstil ny adgangskode til din konto</P>
                  <FormGroup>
                    <Label className="col-form-label">New Password</Label>
                    <div className="form-input position-relative">
                      <Input
                        type={show ? "text" : "password"}
                        placeholder=""
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        name="password"
                        autoComplete="off"
                        className={`form-control`}
                      />
                      <div
                        className={`show-hide is-invalid ${
                          isSafari() ? "show-hide-extended" : ""
                        }`}
                        onClick={() => setShow(!show)}
                      >
                        <span className={show ? "hide" : "show"}> </span>
                      </div>
                    </div>
                    <Collapse isOpen={!!errors?.password}>
                      <span className="form-error-message">
                        {errors?.password}&nbsp;
                      </span>
                    </Collapse>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      Repeat New Password
                    </Label>
                    <div className="form-input position-relative">
                      <Input
                        type={show ? "text" : "password"}
                        placeholder=""
                        onChange={(event) =>
                          setRepeatPassword(event.target.value)
                        }
                        value={repeatPassword}
                        name="repeatPassword"
                        autoComplete="new-password"
                      />
                      <div
                        className={`show-hide is-invalid ${
                          isSafari() ? "show-hide-extended" : ""
                        }`}
                        onClick={() => setShow(!show)}
                      >
                        <span className={show ? "hide" : "show"}> </span>
                      </div>
                    </div>
                    <Collapse isOpen={!!errors?.repeatPassword}>
                      <span className="form-error-message">
                        {errors?.repeatPassword}&nbsp;
                      </span>
                    </Collapse>
                    <Collapse isOpen={!!errors?.passwordNotMatch}>
                      <span className="form-error-message">
                        {errors?.passwordNotMatch}&nbsp;
                      </span>
                    </Collapse>
                  </FormGroup>

                  <FormGroup className="mb-0 form-sub-title">
                    <Collapse isOpen={!!errors?.noToken}>
                      <span className="form-error-message">
                        {errors?.noToken}&nbsp;
                      </span>
                    </Collapse>
                    <div className="text-end mt-3">
                      <Btn color="primary" block className="w-100">
                        Indstil ny adgangskode
                      </Btn>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SetNewPassword;
