import { Button } from "reactstrap";
import { CSSModule } from "reactstrap/types/lib/utils";
import { Icon } from "@iconify/react";

interface StyleProp {
  width: number;
  fontSize: number;
  padding: number;
}

interface PropsTypes {
  children?: React.ReactNode;
  color?: string;
  onClick?: (key: any) => void;
  className?: string;
  id?: string;
  type?: "submit" | "reset" | "button" | undefined;
  outline?: boolean | undefined;
  size?: string;
  style?: CSSModule | StyleProp;
  active?: boolean;
  disabled?: boolean;
  tag?: React.ElementType;
  href?: string;
  value?: string;
  as?: string;
  name?: string;
  cssModule?: CSSModule;
  target?: string;
  title?: string;
  close?: boolean;
  block?: boolean;
  iconName?: string; // Add iconName prop
  iconWidth?: string; // Add iconName prop
}

const ButtonSF = (props: PropsTypes) => {
  const { children, iconName, iconWidth, ...restProps } = props;

  return (
    <Button
      color={props.color ? props.color : "transparent"}
      {...restProps}
      className="btn-custom-sf"
    >
      <span className="btn-label">{children}</span>
      {iconName && <Icon icon={`fluent:${iconName}`} width={iconWidth || 20} />}
    </Button>
  );
};

export default ButtonSF;
