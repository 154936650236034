import { z } from "zod";

export const loginSchema = z.object({
  email: z
    .string()
    .min(1, { message: "E-mail er påkrævet" })
    .email({ message: "Ugyldig e-mailadresse" }),
  password: z.string().min(1, { message: "Adgangskode er påkrævet" }),
});

export const newPasswordSchema = z.object({
  password: z
    .string()
    .min(8, { message: "Adgangskoden skal være 8 tegn eller længere" })
    // .regex(/[A-Z]/, {
    //   message: "Adgangskoden skal indeholde mindst ét stort bogstav",
    // })
    .regex(/\d/, { message: "Adgangskoden skal indeholde mindst ét tal" }),
  repeatPassword: z
    .string()
    .min(1, { message: "Gentag adgangskode er påkrævet" }), // Basic validation, could add more depending on requirements
});

export const registerSchema = z.object({
  email: z
    .string()
    .min(1, { message: "E-mail er påkrævet" })
    .email({ message: "Ugyldig e-mailadresse" }),
  password: z
    .string()
    .min(8, { message: "Adgangskoden skal være 8 tegn eller længere" })
    // .regex(/[A-Z]/, {
    //   message: "Adgangskoden skal indeholde mindst ét stort bogstav",
    // })
    .regex(/\d/, { message: "Adgangskoden skal indeholde mindst ét tal" }),
  repeatPassword: z
    .string()
    .min(1, { message: "Gentag adgangskode er påkrævet" }), // Basic validation, could add more depending on requirements
});

export const forgotPasswordSchema = z.object({
  email: z
    .string()
    .min(1, { message: "E-mail er påkrævet" })
    .email({ message: "Ugyldig e-mailadresse" }),
});
