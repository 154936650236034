import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";
import H3 from "../Headings/H3Element";
import { ButtonSF } from "../../AbstractElements";
import { useAppSelector } from "../../ReduxToolkit/Hooks";

const CasesPageTitle = () => {
  const { soilCase } = useAppSelector((state) => state.singleCaseSlice);
  const [selectedTabId, setSelectedTabId] = useState<string>("ongoing"); // Default tab to "ongoing"
  const { tab } = useParams<{ tab?: string }>(); // Use tab parameter from URL
  const navigate = useNavigate();

  // Define an array for tabs with custom labels
  const tabs = useMemo(
    () => [
      { id: "ongoing", title: "Igangværende" },
      { id: "all", title: "Alle" },
    ],
    []
  );

  // Set initial selected tab based on URL parameter or default to "ongoing"
  useEffect(() => {
    const initialTabId =
      tab && tabs.some((t) => t.id === tab) ? tab : "ongoing";
    setSelectedTabId(initialTabId);
  }, [tab, tabs]);

  const handleCreateNewCase = () => {
    navigate("/case/create");
  };

  const handleEditCase = () => {
    // console.log("Edit case", idCaseToUpdate);
    navigate("/case/edit");
  };

  const selectedTab = tabs.find((t) => t.id === selectedTabId);

  return (
    <Container fluid>
      <div className="py-3 ">
        <Row className="m-0">
          <Col className="p-0 d-flex justify-content-between align-items-center">
            <H3 className="sf-page-title">SAG / {selectedTab?.title}</H3>
            <div className="d-flex flex-direction-row gap-3">
              {soilCase && (
                <ButtonSF
                  color="primary"
                  onClick={handleEditCase}
                  iconName="text-bullet-list-square-edit-20-regular"
                >
                  Rediger valgte
                </ButtonSF>
              )}
              <ButtonSF
                color="primary"
                onClick={handleCreateNewCase}
                iconName="add-circle-20-regular"
              >
                Opret ny jordsag
              </ButtonSF>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default CasesPageTitle;
