import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import RouterData from "./Routes";
import "./App.css";
import { registerLicense } from "@syncfusion/ej2-base";
import { useAppDispatch } from "./ReduxToolkit/Hooks";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import { checkAuthStatus } from "./ReduxToolkit/Reducers/UserSlice";

// Loop through all the icons and add them to the library
const solidIconList = Object.keys(solidIcons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => (solidIcons as any)[icon]);

library.add(...solidIconList);

// Registering Syncfusion license key
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhfcHRQRmhfVEZ1Xko="
);

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  return (
    <>
      <RouterData />
      <ToastContainer />
    </>
  );
}

export default App;
