import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the interface for SoilCase based on the provided JSON object structure
export interface ISoilCase {
  id: number;
  soil_case_no: number;
  main_case_no: number;
  location_no: number;
  location_name: string;
  location_nickname: string;
  location_zipcode: number;
  location_adress: string;
  location_city: string;
  location_longitude: string;
  location_latitude: string;
  location_important_information: string;
  legal_no: string;
  reference_numbers: string;
  customer_no: number;
  customer_name: string;
  customer_nickname: string;
  customer_adress: string;
  customer_zipcode: number;
  customer_city: string;
  work_location_no: number;
  work_location_name: string;
  work_location_nickname: string;
  work_location_adress: string;
  work_location_adress_nickname: string;
  work_location_zipcode: number;
  work_location_city: string;
  work_location_longitude: string;
  work_location_latitude: string;
  work_location_longitude_nickname: number;
  work_location_latitude_nickname: number;
  offer_name: string;
  offer_name_nickname: string;
  product_no: number;
  product_name: string;
  producttype_description: string;
  product_description: string;
  unit: string;
  maincase_status: number;
  soilcase_status: number;
  orderline: number;
  important_information: string;
  internal_soilcase_note: string;
  internal_maincase_note: string;
  customer_case_no: string;
  customer_departmentname: string;
  customer_reference_no: string;
  sender_weighing_no: string;
  customer_request_no: string;
  optional_note: string;
  customer_contact: string;
  customer_product_no: string;
  acces_document: string;
  fixed_internal_label: string;
  app_warning_message: string;
  tons_delivered: number;
  m3_delivered: number;
  loads_delivered: number;
  tons_approved: number;
  m3_approved: number;
  tons_remaining: number;
  m3_remaining: number;
  procentage_of_max: number;
  send_notification_mail: number;
  soilcase_suffix: string;
  expected_daily_loads: number;
  transmit_data: number;
  transmit_data_interval: number;
  always_approve: number;
  app_allow_extra_charge: number;
  extra_charge_must_approve: number;
  allow_email_with_max_to_external: number;
  allow_email_with_weighing_document_to_external: number;
  allow_orientation_of_extra_charge_to_external: number;
  send_automatic_accessdocument_when_created_or_changed_when_activ: number;
}

// Define the initial state for the slice
interface SingleCaseState {
  soilCase: ISoilCase | null;
}

const initialState: SingleCaseState = {
  soilCase: null,
};

// Create the slice
const SingleCaseSlice = createSlice({
  name: "SingleCaseSlice",
  initialState,
  reducers: {
    setSoilCase: (state, action: PayloadAction<ISoilCase>) => {
      state.soilCase = action.payload;
    },
    resetSoilCase: (state) => {
      state.soilCase = null;
    },
  },
});

// Export the actions and reducer
export const { setSoilCase, resetSoilCase } = SingleCaseSlice.actions;
export default SingleCaseSlice.reducer;
