import { Button } from "reactstrap";
import { CSSModule } from "reactstrap/types/lib/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface StyleProp {
  width: number;
  fontSize: number;
  padding: number;
}

interface PropsTypes {
  children?: React.ReactNode;
  color?: string;
  onClick?: (key: any) => void;
  className?: string;
  id?: string;
  type?: "submit" | "reset" | "button" | undefined;
  outline?: boolean | undefined;
  size?: string;
  style?: CSSModule | StyleProp;
  active?: boolean;
  disabled?: boolean;
  tag?: React.ElementType;
  href?: string;
  value?: string;
  as?: string;
  name?: string;
  cssModule?: CSSModule;
  target?: string;
  title?: string;
  close?: boolean;
  block?: boolean;
  iconName?: IconProp; // Add iconName prop
}

const Btn = (props: PropsTypes) => {
  const { children, iconName, ...restProps } = props;

  return (
    <Button
      color={props.color ? props.color : "transparent"}
      {...restProps}
      className="btn-custom"
    >
      <span className="btn-label">{children}</span>
      {iconName && <FontAwesomeIcon icon={iconName} className="btn-icon" />}
    </Button>
  );
};

export default Btn;
