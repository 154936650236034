import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Collapse,
} from "reactstrap";
import { Btn, H4, P } from "../../AbstractElements";
import { useState } from "react";
import { toast } from "react-toastify";
import { registerSchema } from "../../utils/validation/schemas";
import Config from "../../Config/config";
import AuthService from "../../Api/AuthService";
import { isSafari } from "../../utils/helper/helper";
import LogoImage from "../LogoImage/LogoImage";

const Register = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(Config.DEFAULT_EMAIL);
  const [password, setPassword] = useState(Config.DEFAULT_PASSWORD);
  const [repeatPassword, setRepeatPassword] = useState(Config.DEFAULT_PASSWORD);
  const [terms, setTerms] = useState(false);
  const [errors, setErrors] = useState<{
    email: string;
    password: string;
    repeatPassword: string;
    passwordNotMatch: string;
  }>({
    email: "",
    password: "",
    repeatPassword: "",
    passwordNotMatch: "",
  });

  const signupWithEmailHandler = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const newErrors = {
      email: "",
      password: "",
      repeatPassword: "",
      passwordNotMatch: "",
    };
    setErrors((prevState) => newErrors);

    const validation = registerSchema.safeParse({
      email,
      password,
      repeatPassword,
    });

    if (!validation.success) {
      validation.error.errors.forEach((error) => {
        const field = error.path[0] as keyof typeof newErrors;
        if (!newErrors[field]) {
          newErrors[field] = error.message;
        }
      });

      setErrors((_prevState) => newErrors);
      return;
    }

    if (password && repeatPassword && password !== repeatPassword) {
      setErrors((errors) => {
        return { ...errors, passwordNotMatch: "Passwords do not match" };
      });
      return;
    }

    const registerResult = await AuthService.register({
      email,
      password,
    });

    console.log("registerResult", registerResult);

    //TODO create user slice
    toast.success("Your account has been created.");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  };

  const toggleTermsHandler = () => {
    setTerms((prevState) => !prevState);
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div className="login-main">
                <div className="d-flex justify-content-center image-with-border mb-3">
                  <LogoImage />
                </div>
                <Form
                  className="theme-form"
                  onSubmit={(e) => signupWithEmailHandler(e)}
                  noValidate
                >
                  <H4>Opret konto</H4>
                  <P>Opret en konto med din e-mail og adgangskode</P>
                  <FormGroup>
                    <Label className="col-form-label">Email</Label>
                    <Input
                      type="email"
                      required
                      placeholder=""
                      value={email}
                      name="email"
                      onChange={(event) => setEmail(event.target.value)}
                      autoComplete="off"
                      className={`form-control`}
                    />
                    <Collapse isOpen={!!errors?.email}>
                      <span className="form-error-message">
                        {errors?.email}&nbsp;
                      </span>
                    </Collapse>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Password</Label>
                    <div className="form-input position-relative">
                      <Input
                        type={show ? "text" : "password"}
                        placeholder=""
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        name="password"
                        autoComplete="off"
                        className={`form-control`}
                      />
                      <div
                        className={`show-hide is-invalid ${
                          isSafari() ? "show-hide-extended" : ""
                        }`}
                        onClick={() => setShow(!show)}
                      >
                        <span className={show ? "hide" : "show"}> </span>
                      </div>
                    </div>
                    <Collapse isOpen={!!errors?.password}>
                      <span className="form-error-message">
                        {errors?.password}&nbsp;
                      </span>
                    </Collapse>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Repeat Password</Label>
                    <div className="form-input position-relative">
                      <Input
                        type={show ? "text" : "password"}
                        placeholder=""
                        onChange={(event) =>
                          setRepeatPassword(event.target.value)
                        }
                        value={repeatPassword}
                        name="repeatPassword"
                        autoComplete="new-password"
                      />
                      <div
                        className={`show-hide is-invalid ${
                          isSafari() ? "show-hide-extended" : ""
                        }`}
                        onClick={() => setShow(!show)}
                      >
                        <span className={show ? "hide" : "show"}> </span>
                      </div>
                    </div>
                    <Collapse isOpen={!!errors?.repeatPassword}>
                      <span className="form-error-message">
                        {errors?.repeatPassword}&nbsp;
                      </span>
                    </Collapse>
                    <Collapse isOpen={!!errors?.passwordNotMatch}>
                      <span className="form-error-message">
                        {errors?.passwordNotMatch}&nbsp;
                      </span>
                    </Collapse>
                  </FormGroup>

                  <FormGroup className="form-check">
                    <Input
                      name="terms"
                      type="checkbox"
                      onChange={toggleTermsHandler}
                    />
                    <Label for="invalidCheck" check>
                      Accept
                      <Link
                        className="ms-2"
                        to={`${process.env.REACT_APP_API_URL}/terms-and-conditions`}
                      >
                        Terms & Conditions
                      </Link>
                    </Label>
                  </FormGroup>

                  <FormGroup className="mb-0 form-sub-title">
                    <div className="text-end mt-3">
                      <Btn
                        color="primary"
                        block
                        className="w-100"
                        disabled={!terms}
                      >
                        Opret konto
                      </Btn>
                    </div>
                  </FormGroup>

                  {/* <H4 className="text-muted mt-4">Sign Up with Google</H4>
                  <SocialApp /> */}
                  <P className="mt-4 mb-0 text-center">
                    Already have an account?
                    <Link
                      className="ms-2"
                      to={`${process.env.REACT_APP_API_URL}/login`}
                    >
                      Login
                    </Link>
                  </P>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
