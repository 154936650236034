import { MenuItem } from "../../Types/Layout/SidebarType";

export const MenuList: MenuItem[] = [
  {
    title: "Pages",
    Items: [
      {
        id: 1,
        active: false,
        path: `${process.env.REACT_APP_API_URL}/oversigt`,
        title: "Oversigt",
        type: "link",
      },
      {
        id: 2,
        active: false,
        path: `${process.env.REACT_APP_API_URL}/afventende`,
        title: "Afventende",
        type: "link",
      },
      {
        id: 3,
        active: false,
        path: `${process.env.REACT_APP_API_URL}/registrering`,
        title: "Registrering",
        type: "link",
      },
      {
        id: 4,
        active: false,
        path: `${process.env.REACT_APP_API_URL}/cases`,
        title: "Sag",
        type: "link",
      },
      {
        id: 5,
        active: false,
        path: `${process.env.REACT_APP_API_URL}/jordtip`,
        title: "Jordtip",
        type: "link",
      },
      {
        id: 6,
        active: false,
        path: `${process.env.REACT_APP_API_URL}/stamdata`,
        title: "Stamdata",
        type: "link",
      },
      // {
      //   title: "Others",
      //   icon: "others",
      //   id: 25,
      //   type: "sub",
      //   children: [
      //     {
      //       title: "Error Pages",
      //       type: "sub",
      //       children: [
      //         {
      //           title: "Error 400",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/errors/error400`,
      //         },
      //         {
      //           title: "Error 401",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/errors/error401`,
      //         },
      //         {
      //           title: "Error 403",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/errors/error403`,
      //         },
      //         {
      //           title: "Error 404",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/errors/error404`,
      //         },
      //         {
      //           title: "Error 500",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/errors/error500`,
      //         },
      //         {
      //           title: "Error 503",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/errors/error503`,
      //         },
      //       ],
      //     },
      //     {
      //       title: "Authentication",
      //       type: "sub",
      //       children: [
      //         {
      //           title: "Login Simple",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/loginsimple`,
      //         },
      //         {
      //           title: "Login with bg image",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/loginbgimage`,
      //         },
      //         {
      //           title: "Login with image two",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/loginwithimagetwo`,
      //         },
      //         {
      //           title: "Login with validation",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/loginvalidation`,
      //         },
      //         {
      //           title: "Login with tooltip",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/logintooltip`,
      //         },
      //         {
      //           title: "Login with sweetalert",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/loginsweetalert`,
      //         },
      //         {
      //           title: "Register Simple",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/registersimple`,
      //         },
      //         {
      //           title: "Register with Bg Image",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/registerbgimage`,
      //         },
      //         {
      //           title: "Register with Bg Two",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/registerwithimagetwo`,
      //         },
      //         {
      //           title: "Register Wizard",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/registerwizard`,
      //         },
      //         {
      //           title: "Unloack User",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/unlockuser`,
      //         },
      //         {
      //           title: "Forget Password",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/forgetpassword`,
      //         },
      //         {
      //           title: "Reset Password",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/resetpassword`,
      //         },
      //         {
      //           title: "Maintenance",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/authentication/maintenance`,
      //         },
      //       ],
      //     },
      //     {
      //       title: "Coming Soon",
      //       type: "sub",
      //       children: [
      //         {
      //           title: "Coming Simple",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/comingsoon/comingsoonsimple`,
      //         },
      //         {
      //           title: "Coming with Bg Video",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/comingsoon/comingbgvideo`,
      //         },
      //         {
      //           title: "Coming with bg Image",
      //           type: "link",
      //           path: `${process.env.REACT_APP_API_URL}/comingsoon/comingbgimg`,
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
];
