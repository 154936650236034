import React from "react";
import { Image } from "../../AbstractElements";
import { dynamicImage } from "../../Service";

interface LogoImageProps {
  width?: number;
  height?: number;
  customClass?: string;
  logoImagePath?: string;
}

const LogoImage: React.FC<LogoImageProps> = ({
  width,
  height,
  customClass,
  logoImagePath,
}) => {
  const imagePath = logoImagePath || "logo/ScanField_logo.svg";

  return (
    <Image
      className={customClass ? customClass : "img for-light"}
      src={dynamicImage(imagePath)}
      alt="logo"
      height={width || 133}
      width={height || 325}
    />
  );
};

export default LogoImage;
