import CasesPageContainer from "../../Component/CasesPageContainer/CasesPageContainer";

const Cases = () => {
  return (
    <div className="page-body">
      <CasesPageContainer />
    </div>
  );
};

export default Cases;
