import moment from "moment";

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const generateFileName = (fileName: string): string => {
  const now = moment();
  const formattedDate = now.format("YYYYMMDD_HHmmss");

  return `${formattedDate}_${fileName}`;
};

export const isSafari = () => {
  const ua = window.navigator.userAgent;
  return /^((?!chrome|android).)*safari/i.test(ua);
};
