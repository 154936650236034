import { LI, UL } from "../../AbstractElements";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import LogoWrapper from "./LogoWrapper";
import SimpleBar from "simplebar-react";

import SidebarMenuList from "./SidebarMenuList";

const Sidebar = () => {
  const { toggleSidebar } = useAppSelector((state) => state.layout);

  if (toggleSidebar) {
    return null;
  }

  return (
    <div className={`sidebar-wrapper ${toggleSidebar ? "close_icon" : ""}`}>
      <div>
        <LogoWrapper />
        <nav className="sidebar-main">
          <div id="sidebar-menu">
            <UL className="sidebar-links" id="simple-bar">
              <SimpleBar style={{ margin: "0px" }}>
                <LI className="back-btn"></LI>
                <SidebarMenuList />
              </SimpleBar>
            </UL>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
