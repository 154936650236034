import { ApiResponse } from "./AuthService";
import axiosInstance from "./axiosConfig";
import { soilCasesData } from "./jsons/soilCasesData"; // Assuming data is imported here

export class SoilService {
  // Existing method to get data via API
  static async getData(data: any): Promise<ApiResponse<any>> {
    try {
      const response = await axiosInstance.post("auth/login", data);
      return { success: true, data: response.data };
    } catch (error: any) {
      return {
        success: false,
        error: {
          status: error?.response?.status || 500,
          message: error?.response?.data?.message || "Login failed",
        },
      };
    }
  }

  // Method to get all soil data
  static getAllSoilCases(): Promise<ApiResponse<any>> {
    return Promise.resolve({ success: true, data: soilCasesData });
  }

  // Method to get a specific soil case by Id
  static getSoilCaseById(id: number): any | null {
    const soilCase = soilCasesData.find((item: any) => item.Id === id);
    return soilCase || null;
  }

  // Method to add a new soil case
  static addSoilCase(newSoilCase: any): void {
    newSoilCase.Id = soilCasesData.length
      ? Math.max(...soilCasesData.map((item: any) => item.Id)) + 1
      : 1;
    soilCasesData.push(newSoilCase);
  }

  // Method to update an existing soil case by Id
  static updateSoilCase(id: number, updatedData: any): boolean {
    const index = soilCasesData.findIndex((item: any) => item.Id === id);
    if (index !== -1) {
      soilCasesData[index] = { ...soilCasesData[index], ...updatedData };
      return true;
    }
    return false;
  }

  // Method to delete a soil case by Id
  static deleteSoilCase(id: number): boolean {
    const index = soilCasesData.findIndex((item: any) => item.Id === id);
    if (index !== -1) {
      soilCasesData.splice(index, 1);
      return true;
    }
    return false;
  }
}

export default SoilService;
