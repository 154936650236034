import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Collapse,
} from "reactstrap";
import { toast } from "react-toastify";

import { Btn } from "../../AbstractElements";
import { loginSchema } from "../../utils/validation/schemas";
import Config from "../../Config/config";
import AuthService from "../../Api/AuthService";
import LogoImage from "../LogoImage/LogoImage";
import { setUser } from "../../ReduxToolkit/Reducers/UserSlice";

const Login = () => {
  console.log("Login component");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(Config.DEFAULT_EMAIL);
  const [password, setPassword] = useState(Config.DEFAULT_PASSWORD);
  const [errors, setErrors] = useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);

  const signInWithEmailHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({
      email: "",
      password: "",
    });

    const validation = loginSchema.safeParse({ email, password });

    if (!validation.success) {
      const newErrors = {
        email: "",
        password: "",
      };

      validation.error.errors.forEach((error) => {
        const field = error.path[0] as keyof typeof newErrors;
        if (!newErrors[field]) {
          newErrors[field] = error.message;
        }
      });

      setErrors(newErrors);
      return;
    }
    const loginResult = await AuthService.login({
      email,
      password,
      remember_me: rememberMe,
    });
    console.log("loginResult", loginResult);

    if (!loginResult.success) {
      toast.error("Indtast venligst gyldig e-mail og adgangskode!");
      return;
    }
    dispatch(setUser(loginResult.data));
    localStorage.setItem("token", loginResult.data.token);
    navigate(`${process.env.REACT_APP_API_URL}/sag`);
  };

  const toggleRememberMeHandler = () => {
    setRememberMe((prevState) => !prevState);
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div className="login-main">
                <div className="d-flex justify-content-center image-with-border mb-3">
                  <LogoImage />
                </div>
                <Form
                  className="theme-form"
                  onSubmit={(e) => signInWithEmailHandler(e)}
                  noValidate
                >
                  {/* <H4>Login</H4>
                  <P>Enter your email & password to login</P> */}
                  <FormGroup>
                    <Label className="col-form-label">Email</Label>
                    <Input
                      type="email"
                      required
                      placeholder="Indtast dit emailadresse"
                      value={email}
                      name="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <Collapse isOpen={!!errors?.email}>
                      <span className="form-error-message">
                        {errors?.email}&nbsp;
                      </span>
                    </Collapse>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Password</Label>
                    <div className="form-input position-relative">
                      <Input
                        type={show ? "text" : "password"}
                        placeholder="Indtast din adgangskode"
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        name="password"
                      />
                      <div className="show-hide" onClick={() => setShow(!show)}>
                        <span className="show"> </span>
                      </div>
                    </div>
                    <Collapse isOpen={!!errors?.password}>
                      <span className="form-error-message">
                        {errors?.password}&nbsp;
                      </span>
                    </Collapse>
                  </FormGroup>
                  <FormGroup className="form-check">
                    <Input
                      name="terms"
                      type="checkbox"
                      onChange={toggleRememberMeHandler}
                    />
                    <Label for="invalidCheck" check>
                      Remember me?
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    <Link to={`${process.env.REACT_APP_API_URL}/forgot-password`}>
                      Glemt adgangskode ?
                    </Link>
                    <div className="text-end mt-3">
                      <Btn color="primary" block className="w-100">
                        Log ind
                      </Btn>
                    </div>
                  </FormGroup>
                  {/* <P className="mt-4 mb-0 text-center">
                    Har du ikke en konto?
                    <Link
                      className="ms-2"
                      to={`${process.env.REACT_APP_API_URL}/register`}
                    >
                      Opret konto
                    </Link>
                  </P> */}
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
