import { useEffect, useState, useRef, useMemo } from "react";
import {
  TabComponent,
  SelectEventArgs,
} from "@syncfusion/ej2-react-navigations";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CasesPageTitle from "../../CommonElements/CasesPageTitle/CasesPageTitle";
import SoilCasesGrid from "../SoilCasesGrid/SoilCasesGrid";
import GridCardHeaderCommon from "../../CommonElements/GridCardHeaderCommon/GridCardHeaderCommon";
import SoilQuantityGrid from "./SoilQuantityGrid";
import LatestActivities from "./LatestActivitiesGrid";
import RegistrationStatusGrid from "./RegistrationStatusGrid";
import "./styles.css";

const CasesPageContainer = () => {
  const tabInstance = useRef<TabComponent>(null);
  const [selectedTabId, setSelectedTabId] = useState<string>("ongoing"); // Default tab to "ongoing"
  const { tab } = useParams<{ tab?: string }>(); // Use tab parameter from URL
  const navigate = useNavigate();

  // Define an array for tabs with custom labels
  const tabs = useMemo(
    () => [
      { id: "ongoing", title: "(7) Igangværende" },
      { id: "all", title: "(10) Alle" },
    ],
    []
  );

  // Function to handle tab selection
  const selected = (args: SelectEventArgs) => {
    const newTabId = tabs[args.selectedIndex]?.id;
    if (newTabId && newTabId !== selectedTabId) {
      setSelectedTabId(newTabId);
      navigate(`/cases/${newTabId}`); // Update URL when tab is selected
    }
  };

  // Set initial selected tab based on URL parameter or default to "ongoing"
  useEffect(() => {
    const initialTabId =
      tab && tabs.some((t) => t.id === tab) ? tab : "ongoing";
    setSelectedTabId(initialTabId);
    tabInstance.current?.select(tabs.findIndex((t) => t.id === initialTabId));
  }, [tab, tabs]);

  return (
    <>
      <CasesPageTitle />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card className="full-height-card">
              <CardBody className="full-height-card-body">
                <TabComponent
                  id="defaultTab"
                  cssClass="pavlo"
                  ref={tabInstance}
                  selected={selected}
                >
                  <div className="e-tab-header">
                    {tabs.map((tab) => (
                      <div
                        key={tab.id}
                        className={`tab-header ${
                          tab.id === selectedTabId ? "tab-selected" : ""
                        }`}
                      >
                        {tab.title}
                      </div>
                    ))}
                  </div>
                  <div className="e-content">
                    <div>
                      <SoilCasesGrid />
                    </div>
                    <div>
                      <SoilCasesGrid />
                    </div>
                  </div>
                </TabComponent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Card className="full-height-card">
              <GridCardHeaderCommon
                title={"Jordsager tæt på den maksimale anviste mængde"}
              />
              <CardBody className="full-height-card-body">
                <SoilQuantityGrid />
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="full-height-card">
              <GridCardHeaderCommon title={"Seneste aktiviteter – Sag 2122"} />
              <CardBody className="full-height-card-body">
                <LatestActivities />
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="full-height-card">
              <GridCardHeaderCommon title={"Status på registreringer"} />
              <CardBody className="full-height-card-body">
                <RegistrationStatusGrid />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CasesPageContainer;
