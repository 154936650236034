import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Collapse,
} from "reactstrap";
import { Btn, H4, P } from "../../AbstractElements";
import { useState } from "react";
import { toast } from "react-toastify";
import { forgotPasswordSchema } from "../../utils/validation/schemas";
import Config from "../../Config/config";
import AuthService from "../../Api/AuthService";
import LogoImage from "../LogoImage/LogoImage";

const ForgotPassword = () => {
  const [email, setEmail] = useState(Config.DEFAULT_EMAIL);
  const [errors, setErrors] = useState<{ email: string }>({
    email: "",
  });

  const navigate = useNavigate();

  const signInWithEmailHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({
      email: "",
    });

    const validation = forgotPasswordSchema.safeParse({ email });

    if (!validation.success) {
      const newErrors = {
        email: "",
      };

      validation.error.errors.forEach((error) => {
        const field = error.path[0] as keyof typeof newErrors;
        if (!newErrors[field]) {
          newErrors[field] = error.message;
        }
      });

      setErrors(newErrors);
      return;
    }

    await AuthService.forgotPassword({
      email,
    });
    toast.success("Vi har sendt dig en e-mail med en ny adgangskode");
    navigate(`${process.env.REACT_APP_API_URL}/login`);
  };

  console.log("ForgotPassword errors", errors);

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div className="login-main">
                <div className="d-flex justify-content-center image-with-border mb-3">
                  <LogoImage />
                </div>

                <Form
                  className="theme-form"
                  onSubmit={(e) => signInWithEmailHandler(e)}
                  noValidate
                >
                  <H4>Nulstil adgangskode</H4>
                  <P>Indtast din e-mail for at nulstille adgangskoden</P>
                  <FormGroup>
                    <Label className="col-form-label">Email</Label>
                    <Input
                      type="email"
                      required
                      placeholder="Indtast dit emailadresse"
                      value={email}
                      name="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <Collapse isOpen={!!errors?.email}>
                      <span className="form-error-message">
                        {errors?.email}&nbsp;
                      </span>
                    </Collapse>
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    <Link to={`${process.env.REACT_APP_API_URL}/login`}>
                      Back to login
                    </Link>
                    <div className="text-end mt-3">
                      <Btn color="primary" block className="w-100">
                        Nulstil adgangskode
                      </Btn>
                    </div>
                  </FormGroup>

                  {/* <P className="mt-4 mb-0 text-center">
                    Har du ikke en konto?
                    <Link
                      className="ms-2"
                      to={`${process.env.REACT_APP_API_URL}/authentication/register_simple`}
                    >
                      Opret konto
                    </Link>
                  </P> */}
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
