import React from "react";
import { Icon } from "@iconify/react";
import { CSSModule } from "reactstrap/types/lib/utils";

interface StyleProp {
  width: number;
  fontSize: number;
  padding: number;
}

interface PropsTypes {
  children?: React.ReactNode;
  color?: string; // Add color prop type here
  onClick?: (key: any) => void;
  className?: string;
  id?: string;
  type?: "submit" | "reset" | "button" | undefined;
  outline?: boolean | undefined;
  size?: string;
  style?: CSSModule | StyleProp;
  active?: boolean;
  disabled?: boolean;
  tag?: React.ElementType;
  href?: string;
  value?: string;
  as?: string;
  name?: string;
  cssModule?: CSSModule;
  target?: string;
  title?: string;
  close?: boolean;
  block?: boolean;
  iconName?: string;
  iconSize?: string;
}

const IconButtonSF: React.FC<PropsTypes> = ({
  iconName,
  iconSize,
  color,
  onClick,
  className = "",
  style,
  cssModule,
  disabled,
  ...restProps
}) => {
  const combinedClassName = `icon-button ${className}`;
  console.log("color", color);

  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={combinedClassName}
      style={cssModule || style}
      {...restProps}
    >
      {iconName && (
        <Icon
          icon={`fluent:${iconName}`}
          style={{
            width: `${iconSize}px`,
            height: `${iconSize}px`,
            color,
          }}
        />
      )}
    </div>
  );
};

export default IconButtonSF;
