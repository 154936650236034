import Oversigt from "../Pages/Oversigt/Oversigt";
import Afventende from "../Pages/Afventende/Afventende";
import Cases from "../Pages/Case/Cases";
import CreateCase from "../Pages/CreateCase/CreateCase";
import Registrering from "../Pages/Registrering/Registrering";
import Jordtip from "../Pages/Jordtip/Jordtip";
import Stampdata from "../Pages/Stampdata/Stampdata";

export const routes = [
  {
    path: `${process.env.REACT_APP_API_URL}/`,
    Component: <Oversigt />,
  },
  {
    path: `${process.env.REACT_APP_API_URL}/oversigt`,
    Component: <Oversigt />,
  },
  {
    path: `${process.env.REACT_APP_API_URL}/afventende`,
    Component: <Afventende />,
  },
  {
    path: `${process.env.REACT_APP_API_URL}/cases/:tab?`,
    Component: <Cases />,
  },
  {
    path: `${process.env.REACT_APP_API_URL}/case/create`,
    Component: <CreateCase />,
  },
  {
    path: `${process.env.REACT_APP_API_URL}/registrering`,
    Component: <Registrering />,
  },
  {
    path: `${process.env.REACT_APP_API_URL}/jordtip`,
    Component: <Jordtip />,
  },
  {
    path: `${process.env.REACT_APP_API_URL}/stamdata`,
    Component: <Stampdata />,
  },
];
