import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { P } from "../../AbstractElements";
import { Pages, SampleCards, SamplePage } from "../../utils/Constant";
import { samplePageData } from "../../Data/OtherPage/OtherPage";
import Breadcrumbs from "../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../CommonElements/CardHeaderCommon/CardHeaderCommon";

import { data } from "./datasource";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Inject,
  Page,
  PageSettingsModel,
  Sort,
} from "@syncfusion/ej2-react-grids";

const OversigtPageContainer = () => {
  const pageSettings: PageSettingsModel = { pageSize: 6 };
  return (
    <>
      <Breadcrumbs mainTitle={SamplePage} parent={Pages} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeaderCommon title={SampleCards} span={samplePageData} />
              <CardBody>
                <GridComponent
                  dataSource={data}
                  allowPaging={true}
                  pageSettings={pageSettings}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="OrderID"
                      width="100"
                      textAlign="Right"
                    />
                    <ColumnDirective field="CustomerID" width="100" />
                    <ColumnDirective
                      field="EmployeeID"
                      width="100"
                      textAlign="Right"
                    />
                    <ColumnDirective
                      field="Freight"
                      width="100"
                      format="C2"
                      textAlign="Right"
                    />
                    <ColumnDirective field="ShipCountry" width="100" />
                  </ColumnsDirective>
                  <Inject services={[Page, Sort, Filter, Group]} />
                </GridComponent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OversigtPageContainer;
