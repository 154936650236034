import { Link } from "react-router-dom";
import LogoImage from "../../Component/LogoImage/LogoImage";

const LogoWrapper = () => {
  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{ padding: 8, paddingTop: 16 }}
      >
        <Link to={`${process.env.REACT_APP_API_URL}/dashboard/default`}>
          <LogoImage
            width={110}
            height={100}
            customClass={"img-fluid"}
            logoImagePath="logo/ScanField_Menu_logo.png"
          />
        </Link>
      </div>
    </>
  );
};

export default LogoWrapper;
