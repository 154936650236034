export const soilCasesData: Object[] = [
  {
    id: 1,
    soil_case_no: 1001,
    main_case_no: 2001,
    location_no: 3001,
    location_name: "Roskildevej",
    location_nickname: "Roskilde",
    location_zipcode: 4000,
    location_adress: "Roskildevej 12",
    location_city: "Roskilde",
    location_longitude: "12.123456",
    location_latitude: "55.123456",
    location_important_information: "Near the industrial zone",
    legal_no: "LEGAL001",
    reference_numbers: "REF001, REF002",
    customer_no: 5001,
    customer_name: "Zollner",
    customer_nickname: "Zoll",
    customer_adress: "Customer Street 15",
    customer_zipcode: 4000,
    customer_city: "Roskilde",
    work_location_no: 6001,
    work_location_name: "Gjorslev Gods",
    work_location_nickname: "Gjorslev",
    work_location_adress: "Gjorslevvej 5",
    work_location_adress_nickname: "Gjorslev Main",
    work_location_zipcode: 4670,
    work_location_city: "Store Heddinge",
    work_location_longitude: "11.123456",
    work_location_latitude: "54.123456",
    work_location_longitude_nickname: 11,
    work_location_latitude_nickname: 54,
    offer_name: "Klimatilpasning",
    offer_name_nickname: "Climate Project",
    product_no: 7001,
    product_name: "J0",
    producttype_description: "Soil",
    product_description: "High-quality soil for construction",
    unit: "Ton",
    maincase_status: 1,
    soilcase_status: 2,
    orderline: 100,
    important_information: "Urgent project",
    internal_soilcase_note: "Internal note 1",
    internal_maincase_note: "Main case note 1",
    customer_case_no: "CUST001",
    customer_departmentname: "Engineering",
    customer_reference_no: "CUSTREF001",
    sender_weighing_no: "SW001",
    customer_request_no: "REQ001",
    optional_note: "Optional info about the soil case",
    customer_contact: "John Doe",
    customer_product_no: "PROD001",
    acces_document: "Access granted",
    fixed_internal_label: "Urgent",
    app_warning_message: "Check soil quality",
    tons_delivered: 500,
    m3_delivered: 250,
    loads_delivered: 10,
    tons_approved: 480,
    m3_approved: 240,
    tons_remaining: 20,
    m3_remaining: 10,
    procentage_of_max: 96,
    send_notification_mail: 1,
    soilcase_suffix: "SFX1",
    expected_daily_loads: 2,
    transmit_data: 1,
    transmit_data_interval: 5,
    always_approve: 0,
    app_allow_extra_charge: 1,
    extra_charge_must_approve: 1,
    allow_email_with_max_to_external: 0,
    allow_email_with_weighing_document_to_external: 1,
    allow_orientation_of_extra_charge_to_external: 0,
    send_automatic_accessdocument_when_created_or_changed_when_activ: 1,
  },
  {
    id: 2,
    soil_case_no: 1002,
    main_case_no: 2002,
    location_no: 3002,
    location_name: "Hillerødvej",
    location_nickname: "Hillerød",
    location_zipcode: 3400,
    location_adress: "Hillerødvej 7",
    location_city: "Hillerød",
    location_longitude: "12.654321",
    location_latitude: "55.654321",
    location_important_information: "Close to the forest",
    legal_no: "LEGAL002",
    reference_numbers: "REF003, REF004",
    customer_no: 5002,
    customer_name: "Entreprenor",
    customer_nickname: "Ent",
    customer_adress: "Entreprenor Street 10",
    customer_zipcode: 3400,
    customer_city: "Hillerød",
    work_location_no: 6002,
    work_location_name: "Smidstrupvej",
    work_location_nickname: "Smidstrup",
    work_location_adress: "Smidstrupvej 20",
    work_location_adress_nickname: "Smidstrup Main",
    work_location_zipcode: 4700,
    work_location_city: "Næstved",
    work_location_longitude: "11.654321",
    work_location_latitude: "54.654321",
    work_location_longitude_nickname: 11,
    work_location_latitude_nickname: 54,
    offer_name: "Industrisager",
    offer_name_nickname: "Industry Project",
    product_no: 7002,
    product_name: "J1",
    producttype_description: "Sand",
    product_description: "Fine-grained sand",
    unit: "Ton",
    maincase_status: 2,
    soilcase_status: 1,
    orderline: 101,
    important_information: "Special instructions required",
    internal_soilcase_note: "Internal note 2",
    internal_maincase_note: "Main case note 2",
    customer_case_no: "CUST002",
    customer_departmentname: "Logistics",
    customer_reference_no: "CUSTREF002",
    sender_weighing_no: "SW002",
    customer_request_no: "REQ002",
    optional_note: "Optional info about the sand case",
    customer_contact: "Jane Smith",
    customer_product_no: "PROD002",
    acces_document: "Access granted",
    fixed_internal_label: "High priority",
    app_warning_message: "Monitor moisture levels",
    tons_delivered: 600,
    m3_delivered: 300,
    loads_delivered: 15,
    tons_approved: 570,
    m3_approved: 285,
    tons_remaining: 30,
    m3_remaining: 15,
    procentage_of_max: 95,
    send_notification_mail: 1,
    soilcase_suffix: "SFX2",
    expected_daily_loads: 3,
    transmit_data: 1,
    transmit_data_interval: 10,
    always_approve: 0,
    app_allow_extra_charge: 1,
    extra_charge_must_approve: 1,
    allow_email_with_max_to_external: 1,
    allow_email_with_weighing_document_to_external: 1,
    allow_orientation_of_extra_charge_to_external: 0,
    send_automatic_accessdocument_when_created_or_changed_when_activ: 1,
  },
  {
    id: 3,
    soil_case_no: 1003,
    main_case_no: 2003,
    location_no: 3003,
    location_name: "Smidstrupvej",
    location_nickname: "Smidstrup",
    location_zipcode: 4700,
    location_adress: "Smidstrupvej 45",
    location_city: "Næstved",
    location_longitude: "12.876543",
    location_latitude: "55.876543",
    location_important_information: "Main entry blocked",
    legal_no: "LEGAL003",
    reference_numbers: "REF005, REF006",
    customer_no: 5003,
    customer_name: "Entreprenør AS",
    customer_nickname: "Entreprenør",
    customer_adress: "Entreprenørvej 8",
    customer_zipcode: 4600,
    customer_city: "Køge",
    work_location_no: 6003,
    work_location_name: "Smidstrup",
    work_location_nickname: "Smidstrup Site",
    work_location_adress: "Smidstrupvej 45",
    work_location_adress_nickname: "Smidstrup",
    work_location_zipcode: 4700,
    work_location_city: "Næstved",
    work_location_longitude: "11.876543",
    work_location_latitude: "54.876543",
    work_location_longitude_nickname: 11,
    work_location_latitude_nickname: 54,
    offer_name: "Byggesager",
    offer_name_nickname: "Construction Project",
    product_no: 7003,
    product_name: "J2",
    producttype_description: "Gravel",
    product_description: "Coarse gravel",
    unit: "Ton",
    maincase_status: 1,
    soilcase_status: 2,
    orderline: 102,
    important_information: "Heavy machinery required",
    internal_soilcase_note: "Internal note 3",
    internal_maincase_note: "Main case note 3",
    customer_case_no: "CUST003",
    customer_departmentname: "Development",
    customer_reference_no: "CUSTREF003",
    sender_weighing_no: "SW003",
    customer_request_no: "REQ003",
    optional_note: "Optional info about the gravel case",
    customer_contact: "Michael Brown",
    customer_product_no: "PROD003",
    acces_document: "Access granted",
    fixed_internal_label: "Restricted",
    app_warning_message: "Check weight limits",
    tons_delivered: 700,
    m3_delivered: 350,
    loads_delivered: 20,
    tons_approved: 650,
    m3_approved: 325,
    tons_remaining: 50,
    m3_remaining: 25,
    procentage_of_max: 93,
    send_notification_mail: 0,
    soilcase_suffix: "SFX3",
    expected_daily_loads: 4,
    transmit_data: 1,
    transmit_data_interval: 15,
    always_approve: 0,
    app_allow_extra_charge: 0,
    extra_charge_must_approve: 0,
    allow_email_with_max_to_external: 0,
    allow_email_with_weighing_document_to_external: 1,
    allow_orientation_of_extra_charge_to_external: 1,
    send_automatic_accessdocument_when_created_or_changed_when_activ: 0,
  },
];
export const data: Object[] = [
  {
    Id: 1,
    Noter: "3364",
    Jordsag: "2775576",
    Anvisning: "2122",
    Produktnr: "J0",
    Tilbudstext: "Klimatilpasning",
    Arbejdssted: "Roskildevej",
    Kundenavn: "Zollner",
    Jordsstip: "Gjorslev Gods",
    Ton: 113.4,
    M3: 63,
    Laes: 3,
    Ton2: 210,
    M32: 116.6,
    RestIPct: "46.0%",
    Tv: 2,
    Tve: "-",
    Sort: "-",
    Status: "Aktiv",
  },
  {
    Id: 2,
    Noter: "3365",
    Jordsag: "2775560",
    Anvisning: "2122",
    Produktnr: "J1",
    Tilbudstext: "Klimatilpasning",
    Arbejdssted: "Roskildevej",
    Kundenavn: "Zollner",
    Jordsstip: "Gjorslev Gods",
    Ton: 331.2,
    M3: 184,
    Laes: 12,
    Ton2: 390,
    M32: 216.6,
    RestIPct: "15.1%",
    Tv: "-",
    Tve: "-",
    Sort: "4",
    Status: "Aktiv",
  },
  {
    Id: 3,
    Noter: "3545",
    Jordsag: "2612451",
    Anvisning: "32529",
    Produktnr: "J0",
    Tilbudstext: "Industrisager",
    Arbejdssted: "Industrivej 9",
    Kundenavn: "Entreprenor",
    Jordsstip: "Smidstrupvej",
    Ton: 495,
    M3: 275,
    Laes: 18,
    Ton2: 460,
    M32: 255.5,
    RestIPct: "-7.6%",
    Tv: 1,
    Tve: 1,
    Sort: 2,
    Status: "Faerdigkort",
  },
];
