import { useState, useEffect } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  EditSettingsModel,
  RowSelectEventArgs,
  ColumnChooser,
  PageSettingsModel,
  FilterSettingsModel,
  Page,
  Edit,
  Inject,
  Toolbar,
  ToolbarItems,
  Resize,
  Filter,
  Reorder,
  Selection,
  SelectionSettingsModel,
  ActionEventArgs,
} from "@syncfusion/ej2-react-grids";
import { DataUtil } from "@syncfusion/ej2-data";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import {
  setSoilCase,
  resetSoilCase,
  ISoilCase,
} from "../../ReduxToolkit/Reducers/SingleCaseSlice";
import "./style.css";

import SoilService from "../../Api/SoilService";

const SoilCasesGrid = () => {
  const dispatch = useAppDispatch();
  let grid: GridComponent | null;
  const [gridKey, setGridKey] = useState(0);
  const forceRerender = () => {
    setGridKey((prevKey) => prevKey + 1);
  };
  const [data, setData] = useState<any>([]);
  const [tvDataSourceFilter, setTvDataSourceFilter] = useState<any>([]);

  useEffect(() => {
    // Fetch data from SoilService and set it to the data state
    const fetchData = async () => {
      const response = await SoilService.getAllSoilCases();
      if (response.success) {
        setData(response.data);

        const tvArr = Array.from(
          new Set(response.data.map((item: any) => item.expected_daily_loads))
        );
        const preparedValue = [
          {
            id: 0,
            label: "All",
            value: -1,
          },
        ];
        tvArr.forEach((item: any, index: number) => {
          preparedValue.push({ id: index + 1, label: item, value: item });
        });
        setTvDataSourceFilter(preparedValue);

        forceRerender();
        // const tmpTonValues = DataUtil.distinct(
        //   data,
        //   "tons_delivered"
        // ) as string[];
      }
    };
    fetchData();
  }, []);

  const pageSettings: PageSettingsModel = { pageSize: 5 };
  const filterSettings: FilterSettingsModel = {
    type: "FilterBar",
    mode: "Immediate",
    showFilterBarStatus: true,
  };

  // Callback function for row selection
  const handleRowSelected = (args: RowSelectEventArgs) => {
    const selectedRowData = args.data as ISoilCase;
    if (selectedRowData) {
      dispatch(setSoilCase(selectedRowData));
      console.log("Selected Row Data:", selectedRowData);
    }
  };

  const handleRowDeslected = () => {
    console.log("Row deselected");
    dispatch(resetSoilCase());
  };

  const selectionSettings: SelectionSettingsModel = {
    mode: "Row", // Enables row selection
    cellSelectionMode: "Flow", // Ensures only rows, not individual cells, can be selected
  };

  const editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Normal",
  };

  const toolbarOptions: ToolbarItems[] = [
    // "Add",
    // "Edit",
    // "Delete",
    // "Update",
    // "Cancel",
    "ColumnChooser",
  ];

  const modtagetColumns = [
    {
      field: "tons_delivered",
      headerText: "Ton",
      width: 100,
      minWidth: 50,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
    {
      field: "m3_delivered",
      headerText: "M3",
      width: 100,
      minWidth: 50,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
    {
      field: "loads_delivered",
      headerText: "Læs",
      width: 100,
      minWidth: 50,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
  ];

  const godkendtColumns = [
    {
      field: "loads_delivered",
      headerText: "Ton",
      width: 100,
      minWidth: 50,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
    {
      field: "m3_approved",
      headerText: "M3",
      width: 100,
      minWidth: 50,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
  ];
  const restColumns = [
    {
      field: "tons_remaining",
      headerText: "Ton",
      width: 100,
      minWidth: 50,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
    {
      field: "m3_remaining",
      headerText: "M3",
      width: 100,
      minWidth: 50,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
    {
      field: "procentage_of_max",
      headerText: "Rest i %",
      width: 100,
      minWidth: 50,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
  ];

  const tillaegColumns = [
    {
      field: "expected_daily_loads",
      headerText: "Tv",
      width: 100,
      textAlign: "Left",
      filterTemplate: () =>
        getFilterTemplate("tv_filter_id", tvDataSourceFilter, "Select Tv"),
    },
    {
      field: "expected_daily_loads",
      headerText: "Tve",
      width: 100,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
    {
      field: "procentage_of_max",
      headerText: "Sort",
      width: 100,
      textAlign: "Left",
      filter: { operator: "contains" },
    },
  ];

  const groupGenericHeader = (headerText: string) => {
    return (
      <div className="text-center">
        <span className="e-headertext">{headerText}</span>
      </div>
    );
  };

  const getFilterTemplate = (
    fileterTemplateId: string,
    filterDataObject: any,
    placeholderText: string
  ) => {
    return (
      filterDataObject.length > 0 && (
        <DropDownListComponent
          key={filterDataObject.length} // Remounts when options change
          id={fileterTemplateId}
          dataSource={filterDataObject}
          fields={{ text: "label", value: "value" }}
          onChange={onChangeFilter}
          placeholder={placeholderText}
          value="All"
        />
      )
    );
  };

  const onChangeFilter = (selected: any) => {
    const selectedValue = selected.value;
    if (!grid) {
      return;
    }

    if (selectedValue === -1) {
      grid.clearFiltering();
    } else {
      grid.filterByColumn("expected_daily_loads", "equal", selectedValue);
    }
  };

  const handleActionComplete = (args: ActionEventArgs) => {
    console.log("args.requestType", args.requestType);

    if (args.requestType === "columnstate") {
      // The "OK" button was pressed in the Column Chooser dialog
      console.log("Column Chooser OK pressed");
      // forceRerender();
      if (grid) {
        grid.reorderColumnByIndex(0, 1);
        grid.reorderColumnByIndex(1, 0);
      }
      // You can add your custom logic here
    }
  };

  console.log("tvDataSourceFilter", tvDataSourceFilter);

  return (
    <GridComponent
      key={gridKey}
      dataSource={data}
      toolbar={toolbarOptions}
      height={400}
      rowSelected={handleRowSelected}
      rowDeselected={handleRowDeslected} // Register the rowSelected event
      showColumnChooser={true}
      allowResizing={true}
      allowReordering={true}
      allowFiltering={true}
      selectionSettings={selectionSettings}
      // editSettings={editOptions}
      // allowPaging={true}
      pageSettings={pageSettings}
      filterSettings={filterSettings}
      ref={(g) => (grid = g)}
      actionComplete={handleActionComplete}
      gridLines={"Horizontal"}
    >
      <ColumnsDirective>
        <ColumnDirective
          field="id"
          headerText="Id"
          isPrimaryKey={true}
          visible={false}
          showInColumnChooser={false}
        />
        <ColumnDirective
          field="internal_soilcase_note"
          headerText="Noter"
          textAlign="Left"
          width="100"
          filter={{ operator: "contains" }}
        />
        <ColumnDirective
          field="soil_case_no"
          headerText="Jordsag"
          textAlign="Left"
          width="120"
          filter={{ operator: "contains" }}
        />

        <ColumnDirective
          field="legal_no"
          headerText="Anvisning"
          textAlign="Left"
          width="120"
          filter={{ operator: "contains" }}
        />
        <ColumnDirective
          field="product_no"
          headerText="Produkt Nr."
          textAlign="Left"
          width="100"
          filter={{ operator: "contains" }}
        />
        <ColumnDirective
          field="offer_name"
          headerText="Tilbudsnavn"
          textAlign="Left"
          width="150"
          filter={{ operator: "contains" }}
        />
        <ColumnDirective
          field="work_location_name"
          headerText="Arbejdssted"
          textAlign="Left"
          width="150"
          filter={{ operator: "contains" }}
        />
        <ColumnDirective
          field="customer_name"
          headerText="Kundenavn"
          textAlign="Left"
          width="150"
          filter={{ operator: "contains" }}
        />
        <ColumnDirective
          field="internal_soilcase_note"
          headerText="Jordsstip"
          textAlign="Left"
          width="150"
          filter={{ operator: "contains" }}
        />

        <ColumnDirective
          headerText="Modtaget"
          width="150"
          //@ts-ignore
          columns={modtagetColumns}
          headerTemplate={() => groupGenericHeader("Modtaget")}
          filter={{ operator: "contains" }}
        />

        <ColumnDirective
          headerText="Godkendt"
          width="100"
          //@ts-ignore
          columns={godkendtColumns}
          headerTemplate={() => groupGenericHeader("Godkendt")}
          filter={{ operator: "contains" }}
        />

        <ColumnDirective
          headerText="Rest"
          width="100"
          //@ts-ignore
          columns={restColumns}
          headerTemplate={() => groupGenericHeader("Rest")}
          filter={{ operator: "contains" }}
        />
        <ColumnDirective
          headerText="Tillæg"
          //@ts-ignore
          columns={tillaegColumns}
          headerTemplate={() => groupGenericHeader("Tillæg")}
          filter={{ operator: "contains" }}
        />

        <ColumnDirective
          field="maincase_status"
          headerText="Status"
          textAlign="Left"
          width="100"
          filter={{ operator: "contains" }}
        />
      </ColumnsDirective>
      <Inject
        services={[
          Edit,
          Toolbar,
          ColumnChooser,
          Resize,
          Filter,
          Page,
          Selection,
          Reorder,
        ]}
      />
    </GridComponent>
  );
};

export default SoilCasesGrid;
