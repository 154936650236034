import axiosInstance from "./axiosConfig";

interface AuthData {
  email: string;
  password: string;
  remember_me?: boolean;
}

interface PasswordResetData {
  password: string;
  password_confirmation: string;
  token: string;
}

interface ForgotPassword {
  email: string;
}

export interface ApiResponse<T> {
  success: boolean;
  data?: T;
  error?: {
    status: number;
    message: string;
  };
}

export class AuthService {
  static async register(data: AuthData): Promise<ApiResponse<any>> {
    try {
      const response = await axiosInstance.post("auth/register", data);
      return { success: true, data: response.data };
    } catch (error: any) {
      return {
        success: false,
        error: {
          status: error?.response?.status || 500,
          message: error?.response?.data?.message || "Registration failed",
        },
      };
    }
  }

  static async login(data: AuthData): Promise<ApiResponse<any>> {
    try {
      const response = await axiosInstance.post("auth/login", {
        ...data,
        device_token: "rem",
      });
      return { success: true, data: response.data };
    } catch (error: any) {
      // return { success: true, data: { email: data.email } };
      return {
        success: false,
        error: {
          status: error?.response?.status || 500,
          message: error?.response?.data?.message || "Login failed",
        },
      };
    }
  }

  static async logout(): Promise<ApiResponse<any>> {
    try {
      const response = await axiosInstance.get("auth/logout");
      return { success: true, data: response.data };
    } catch (error: any) {
      return {
        success: false,
        error: {
          status: error?.response?.status || 500,
          message: error?.response?.data?.message || "Logout failed",
        },
      };
    }
  }

  static async forgotPassword(data: ForgotPassword): Promise<ApiResponse<any>> {
    try {
      const response = await axiosInstance.post("auth/password-request", data);
      return { success: true, data: response.data };
    } catch (error: any) {
      return {
        success: false,
        error: {
          status: error?.response?.status || 500,
          message:
            error?.response?.data?.message || "Forgot password request failed",
        },
      };
    }
  }

  static async passwordReset(
    data: PasswordResetData
  ): Promise<ApiResponse<any>> {
    try {
      const response = await axiosInstance.post("auth/password-reset", data);
      return { success: true, data: response.data };
    } catch (error: any) {
      return {
        success: false,
        error: {
          status: error?.response?.status || 500,
          message: error?.response?.data?.message || "Registration failed",
        },
      };
    }
  }

  static async checkMe(): Promise<ApiResponse<any>> {
    try {
      const response = await axiosInstance.get("auth/me", {
        params: { extra: "remembered" },
      });

      console.log("auth/me response", response.data);

      return { success: true, data: response.data };
    } catch (error: any) {
      return {
        success: false,
        error: {
          status: error?.response?.status || 500,
          message: error?.response?.data?.message || "Check me failed",
        },
      };
    }
  }
}

export default AuthService;
