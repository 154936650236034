import { useEffect, useState, useRef, useMemo } from "react";
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PageTitle from "../../CommonElements/PageTitle/PageTitle";

const CreatePageContainer = () => {
  const tabInstance = useRef<TabComponent>(null);
  const navigate = useNavigate();

  return (
    <>
      <PageTitle pageTitle="SAG / OPRET JORDSAG" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card className="full-height-card">
              <CardBody className="full-height-card-body"></CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreatePageContainer;
