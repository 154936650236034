import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuthService, { ApiResponse } from "../../Api/AuthService";

interface User {
  id: number;
  name: string;
  email: string;
  active: number;
  createdAt: string;
  updatedAt: string;
  token: string;
}

interface UserState {
  user: User | null;
  isAuthenticated: boolean;
}

// Async thunk to check authentication status
export const checkAuthStatus = createAsyncThunk(
  "UserSlice/checkAuthStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response: ApiResponse<any> = await AuthService.checkMe();
      if (response.success) {
        return response.data;
      } else {
        return rejectWithValue(response.error);
      }
    } catch (error) {
      return rejectWithValue({ message: "Check auth status failed" });
    }
  }
);

const initialState: UserState = {
  user: null,
  isAuthenticated: false,
};

const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(checkAuthStatus.rejected, (state) => {
        state.user = null;
        state.isAuthenticated = false;
      });
  },
});

export const { setUser, logout } = UserSlice.actions;
export default UserSlice.reducer;
