const Config = {
  DEFAULT_EMAIL: process.env.REACT_APP_DEFAULT_EMAIL || "",
  DEFAULT_PASSWORD: process.env.REACT_APP_DEFAULT_PASSWORD || "",
};

// export const baseURL = "https://dev-hcsadmin.mobicom-pro.com/";
// export const baseURL = "http://127.0.0.1:8000/";

export const baseURL = process.env.REACT_APP_BASE_URL || "";

export default Config;
