import CreatePageContainer from "../../Component/CreatePageContainer/CreatePageContainer";

const CreateCase = () => {
  return (
    <div className="page-body">
      <CreatePageContainer />
    </div>
  );
};

export default CreateCase;
