import { Col, Container } from "reactstrap";
import { H3, P } from "../../../../AbstractElements";
import { CommonErrorPageType } from "../../../../Types/OtherPages/OtherPages";

const CommonError: React.FC<CommonErrorPageType> = ({ src }) => {
  return (
    <div className="page-wrapper compact-wrapper">
      <div className="error-wrapper">
        <Container>
          <div className="svg-wrraper">{src}</div>
          <Col md="8" className="offset-md-2">
            <H3>Page Not Found</H3>
            <P className="sub-content">
              {
                "The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved."
              }
            </P>
          </Col>
        </Container>
      </div>
    </div>
  );
};

export default CommonError;
