import OversigtPageContainer from "../../Component/OversigtPageContainer/OversigtPageContainer";

const Oversigt = () => {
  return (
    <div className="page-body">
      <OversigtPageContainer />
    </div>
  );
};

export default Oversigt;
