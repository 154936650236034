import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "../Component/Authentication/Login";
import ForgotPassword from "../Component/Authentication/ForgotPassword";
import Register from "../Component/Authentication/Register";
import SetNewPassword from "../Component/Authentication/SetNewPassword";
import Error404 from "../Pages/Error404/Error404";
import { useAppSelector } from "../ReduxToolkit/Hooks";
import Layout from "../Layout/Layout";
import { routes } from "./Route";

export const defaultPageUrl = "cases";

const RouterData = () => {
  const { user } = useAppSelector((state) => state.userData);
  // const user = true;
  console.log("user", user);

  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        {/* Redirect to login if user is not logged in and accessing the root path */}
        <Route
          path="/"
          element={
            user ? (
              <Navigate to={`${process.env.REACT_APP_API_URL}/${defaultPageUrl}`} />
            ) : (
              <Navigate to={`${process.env.REACT_APP_API_URL}/login`} />
            )
          }
        />

        {/* Protected Routes */}
        {user &&
          routes.map(({ path, Component }, i) => (
            <Route element={<Layout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          ))}

        {/* Public Authentication Routes */}
        <Route
          path={`${process.env.REACT_APP_API_URL}/login`}
          element={
            user ? <Navigate to={`${process.env.REACT_APP_API_URL}/`} /> : <Login />
          }
        />
        <Route
          path={`${process.env.REACT_APP_API_URL}/register`}
          element={
            user ? <Navigate to={`${process.env.REACT_APP_API_URL}/`} /> : <Register />
          }
        />
        <Route
          path={`${process.env.REACT_APP_API_URL}/forgot-password`}
          element={
            user ? (
              <Navigate to={`${process.env.REACT_APP_API_URL}/`} />
            ) : (
              <ForgotPassword />
            )
          }
        />
        <Route
          path={`${process.env.REACT_APP_API_URL}/set-new-password/:token?`}
          element={
            user ? (
              <Navigate to={`${process.env.REACT_APP_API_URL}/`} />
            ) : (
              <SetNewPassword />
            )
          }
        />

        {/* Catch-all route for unmatched paths */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
