import { Col, Container, Row } from "reactstrap";
import H3 from "../Headings/H3Element";

const PageTitle = ({ pageTitle }: { pageTitle: string }) => {
  return (
    <Container fluid>
      <div className="py-3 ">
        <Row className="m-0">
          <Col className="p-0 d-flex justify-content-between align-items-center">
            <H3 className="sf-page-title">{pageTitle}</H3>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default PageTitle;
